import React, { FC } from 'react';

import { ApiTransactionSummaryAttributes } from 'store/api/api.types';
import { DEPOSIT_TIME_LIMITS } from 'components/dedicated/organization/TransactionDetail/OnRampTransaction/OnRampTransaction.constants';
import { colorYellow } from 'styles/_colors';
import { formatAssetType, getUriId } from 'utils/format';
import Icon from 'components/core/Icon/Icon';
import LoaderText from 'components/core/LoaderText/LoaderText';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';

import DepositInstructions from '../DepositInstructions/DepositInstructions';

const OnRampTransaction: FC<{
  showTitle?: boolean;
  transactionAttributes: ApiTransactionSummaryAttributes;
}> = ({ showTitle, transactionAttributes }) => {
  const assetTicker = getUriId(transactionAttributes.source.assetType);
  const depositTimeframe = DEPOSIT_TIME_LIMITS[assetTicker];

  return (
    <>
      <Text align='center'>
        {showTitle && (
          <>
            <Icon color={colorYellow} iconName='circledCheckmarkContour' size={4} />
            <Text marginTop={3} variant='sectionHeaderLarge'>
              Confirmed & Awaiting Funds
            </Text>
          </>
        )}
        <Text align='center' marginBottom={5} marginTop={5} variant='bodyCopySmall'>
          Please send {formatAssetType(transactionAttributes.source)} using the bank information
          below. Upon receipt, we&apos;ll promptly send{' '}
          {formatAssetType(transactionAttributes.destination)}. Reviews may result in delays or fund
          holds.
        </Text>
      </Text>
      {depositTimeframe && (
        <Noticebox marginBottom={4} variant='warning'>
          {assetTicker} must be received within {depositTimeframe}, or the transaction will be
          cancelled.
        </Noticebox>
      )}
      {transactionAttributes.ramp?.depositInstructions ||
      transactionAttributes.fx?.payInInstructions ? (
        <DepositInstructions transactionAttributes={transactionAttributes} />
      ) : (
        <LoaderText
          align='center'
          label='Generating instructions'
          marginBottom={25}
          marginTop={25}
        />
      )}
    </>
  );
};

export default OnRampTransaction;

import React, { FC } from 'react';
import cx from 'classnames';

import { circledCross, document } from 'components/core/Svg/icons';
import Button from 'components/core/Button/Button';
import Svg from 'components/core/Svg/Svg';

import { UploadedFileFieldProps } from './UploadedFileField.types';
import styles from './UploadedFileField.module.scss';

const UploadedFileField: FC<UploadedFileFieldProps> = ({
  className,
  dataTestId = '',
  onRemoveFile,
  fileName,
}) => (
  <div className={cx(styles.root, className)} data-testid={dataTestId}>
    <Svg className={styles.fileIcon} img={document} size={1.8} />
    <div className={styles.fileName}>{fileName}</div>
    <Button
      className={styles.deleteButton}
      Icon={<Svg img={circledCross} size={1.8} />}
      onClick={() => onRemoveFile()}
      variant='icon'
    />
  </div>
);

export default UploadedFileField;

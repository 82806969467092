import React, { FC, useCallback, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { COUNTRIES } from 'constants/countries';
import { Identity, Organization } from 'store/api/api.types';
import { useCurrentUser } from 'context/CurrentUserContext';
import Button from 'components/core/Button/Button';
import EmptyField from 'components/core/Form/EmptyField/EmptyField';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import modalService from 'services/modalService';

import styles from './ModalIdentityDetails.module.scss';

import { IdentityDetailsComponentId } from '../config/identityDetails.types';
import {
  defaultIdentityDetailsComponentsConfig,
  identityDetailsComponentsOrder,
} from '../config/identityDetails';
import ModalIdentityDelete from '../ModalIdentityDelete/ModalIdentityDelete';

const ModalIdentityDetails: FC<{
  identity: Identity;
  organizationId: Organization['id'];
}> = ({ identity, organizationId }) => {
  const { isUserAllowedTo } = useCurrentUser();
  const canDeleteIdentity = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.DeleteIdentity),
    [organizationId, isUserAllowedTo],
  );

  const identityDetails = useMemo(() => {
    const { addressPostalCode, addressStateProvince, addressCity, addressCountry, ...rest } =
      identity;
    const parsedIdentity = {
      ...rest,
      city: addressCity,
      country: addressCountry,
      postalCode: addressPostalCode,
      stateProvince: addressStateProvince,
    };

    const details: { componentId: IdentityDetailsComponentId; label: string; value: string }[] = [];
    identityDetailsComponentsOrder.forEach(componentId => {
      if (parsedIdentity[componentId] && parsedIdentity[componentId] !== ' ') {
        details.push({
          componentId,
          label: defaultIdentityDetailsComponentsConfig[componentId].props.label || '',
          value: parsedIdentity[componentId]!,
        });
      }
    });
    return details;
  }, [identity]);

  const getDisplayValue = useCallback((componentId: IdentityDetailsComponentId, value: string) => {
    switch (componentId) {
      case IdentityDetailsComponentId.country:
        return COUNTRIES.find(country => country.code === value)?.name;
      default:
        return value;
    }
  }, []);

  return (
    <>
      <ModalContent variant='noVerticalPadding'>
        {identityDetails.map(({ componentId, label, value }) => (
          <EmptyField key={label} label={label}>
            {getDisplayValue(componentId, value)}
          </EmptyField>
        ))}
      </ModalContent>
      <ModalContent className={styles.bottomButtons} variant='footerButtonsWithoutTopBorder'>
        {canDeleteIdentity && (
          <Button
            label='Delete Sender/Recipient'
            onClick={() =>
              modalService.openModal(
                {
                  title: 'Delete?',
                },
                <ModalIdentityDelete identity={identity} organizationId={organizationId} />,
              )
            }
            variant='destructiveOutlined'
          />
        )}
        <Button
          label='Close'
          onClick={() => modalService.closeCurrentModal()}
          variant='secondary'
        />
      </ModalContent>
    </>
  );
};

export default ModalIdentityDetails;

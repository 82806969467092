import { FetchBaseQueryError } from '@reduxjs/toolkit/query';

import { ERROR_CODES } from 'constants/errorCodes';
import { showError } from 'services/notificationService';

export const showSpecificError = (error: FetchBaseQueryError): void => {
  const errorData: any = error.data;
  const errorCode = errorData.errors[0]?.code;
  if (errorCode in ERROR_CODES) {
    showError(ERROR_CODES[errorCode]);
  } else {
    showError(errorData.errors[0].title);
  }
};

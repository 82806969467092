import React, { FC, useMemo, useState } from 'react';

import {
  ApiIdentityFilterParams,
  ApiRequestParams,
  Identities,
  Organization,
} from 'store/api/api.types';
import { TabProps } from 'components/core/Tab/Tab.types';
import { isGenericErrorType } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { toLowerCase } from 'utils/toLowerCase';
import {
  useCreateBankAccountMutation,
  useCreateIdentityMutation,
  useLazyGetIdentitiesQuery,
} from 'store/api/platformApi';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Tabs from 'components/core/Tabs/Tabs';
import modalService from 'services/modalService';

import { BankDetails } from '../FormBankDetails/FormBankDetails.types';
import { BankDetailsComponentId } from '../config/bankDetails.types';
import { IdentityDetails } from '../FormIdentityDetails/FormIdentityDetails.types';
import {
  areBankDetailsValid,
  defaultBankDetails,
  getBankTitle,
} from '../config/bankDetails.helpers';
import {
  areIdentityDetailsValid,
  defaultIdentityDetails,
  getIdentityTitle,
} from '../config/identityDetails.helpers';
import { getFilteredBankDetailFormValues } from '../config/bankDetails';
import { getFilteredIdentityDetailFormValues } from '../config/identityDetails';
import FormBankDetails from '../FormBankDetails/FormBankDetails';
import FormIdentityDetails from '../FormIdentityDetails/FormIdentityDetails';
import ModalIdentityDuplicate from '../ModalIdentityDuplicate/ModalIdentityDuplicate';

const ModalIdentityCreate: FC<{
  organizationId: Organization['id'];
}> = ({ organizationId }) => {
  const [createIdentity, { isLoading: isCreatingIdentity }] = useCreateIdentityMutation();
  const [createBankAccount, { isLoading: isCreatingBankAccount }] = useCreateBankAccountMutation();
  const [getIdentities, { isFetching: isFetchingIdentities }] = useLazyGetIdentitiesQuery();
  const [isBusiness, setIsBusiness] = useState(true);

  const [bankDetails, setBankDetails] = useState<BankDetails>(defaultBankDetails);
  const setBankDetailsField = (
    field: keyof BankDetails | BankDetailsComponentId,
    value: string,
  ) => {
    setBankDetails(details => ({
      ...details,
      [field]: value,
    }));
  };

  const [identityDetails, setIdentityDetails] = useState<IdentityDetails>(defaultIdentityDetails);
  const setIdentityDetailsField = (field: keyof IdentityDetails, value: string) => {
    setIdentityDetails(details => ({
      ...details,
      [field]: value,
    }));
  };

  const bankTitle = useMemo(() => getBankTitle(bankDetails), [bankDetails]);

  const identityTitle = useMemo(
    () => getIdentityTitle(identityDetails, isBusiness),
    [identityDetails, isBusiness],
  );

  const isProcessing = useMemo(
    () => isFetchingIdentities || isCreatingBankAccount || isCreatingIdentity,
    [isFetchingIdentities, isCreatingBankAccount, isCreatingIdentity],
  );

  const canCreate = useMemo(() => {
    return (
      areIdentityDetailsValid(identityDetails, bankDetails, isBusiness) &&
      areBankDetailsValid(bankDetails)
    );
  }, [identityDetails, isBusiness, bankDetails]);

  const hanldeCreateIdentity = async () => {
    let identityId = '';

    const { address, city, country, postalCode, stateProvince, ...remainingData } =
      getFilteredIdentityDetailFormValues(identityDetails, bankDetails, isBusiness);

    const identityResult = await createIdentity({
      data: {
        address: {
          address,
          city,
          country,
          postalCode,
          stateProvince,
        },
        nationality: country,
        ...remainingData,
      },
      organizationId,
    });

    if (!('error' in identityResult)) {
      showSuccess(`You have successfully added ${identityTitle} as a new sender/recipient.`);
      identityId = identityResult.data.id || '';
    } else {
      showError(
        `${identityTitle} was unable to be successfully added. Please try again. If the issue continues, please contact support.`,
      );
      return;
    }

    const bankAccountResult = await createBankAccount({
      data: {
        attributes: getFilteredBankDetailFormValues(bankDetails),
        relationships: {
          identities: {
            data: [{ id: identityId, type: 'identity' }],
          },
        },
        type: 'bank-account',
      },
      organizationId,
    });

    if (!('error' in bankAccountResult)) {
      showSuccess(`You have successfully added ${bankTitle} as a new bank.`);
    } else if (isGenericErrorType(bankAccountResult.error)) {
      showError(
        `${bankTitle} was unable to be successfully added. Please try again. If the issue continues, please contact support.`,
      );
    }

    modalService.closeCurrentModal();
  };

  const handleFormSubmit = async e => {
    e.preventDefault();

    const filter: ApiIdentityFilterParams = {};
    let identityName: string;

    if (isBusiness) {
      identityName = identityDetails.businessName;
      filter.businessName = identityDetails.businessName;
    } else {
      identityName = getIdentityTitle(identityDetails, isBusiness);
      filter.firstName = identityDetails.firstName;
      filter.lastName = identityDetails.lastName;
    }

    const params: ApiRequestParams = { filter };

    getIdentities({ organizationId, params }).then(result => {
      if (result.isSuccess) {
        const identities: Identities = result.data;

        const identityMatches = identities.data.filter(
          identity =>
            (toLowerCase(identity.businessName) === toLowerCase(identityDetails.businessName) ||
              (toLowerCase(identity.firstName) === toLowerCase(identityDetails.firstName) &&
                toLowerCase(identity.lastName) === toLowerCase(identityDetails.lastName))) &&
            toLowerCase(identity.addressCountry) === toLowerCase(identityDetails.country),
        );

        if (identityMatches && identityMatches.length > 0) {
          modalService.openModal(
            { title: 'Are You Sure?' },
            <ModalIdentityDuplicate
              createIdentity={hanldeCreateIdentity}
              identityName={`${identityName} (${identityDetails.country})`}
            />,
          );
        } else {
          hanldeCreateIdentity();
        }
      }

      if (result.isError) {
        hanldeCreateIdentity();
      }
    });
  };

  const tabs: TabProps[] = [
    {
      isActive: isBusiness,
      label: 'Business',
      onClick: () => {
        setIsBusiness(true);
      },
    },
    {
      isActive: !isBusiness,
      label: 'Person',
      onClick: () => {
        setIsBusiness(false);
      },
    },
  ];

  return (
    <form onSubmit={handleFormSubmit}>
      <ModalContent variant='noBottomPadding'>
        <Tabs tabs={tabs} variant='picker' />
        <FormBankDetails
          bankDetails={bankDetails}
          isProcessing={isProcessing}
          setBankDetailsField={setBankDetailsField}
        />
        <FormIdentityDetails
          bankDetails={bankDetails}
          identityDetails={identityDetails}
          isBusiness={isBusiness}
          isProcessing={isProcessing}
          setIdentityDetailsField={setIdentityDetailsField}
        />
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          label='Cancel'
          onClick={() => modalService.closeCurrentModal()}
          variant='secondary'
        />
        <Button
          isDisabled={!canCreate}
          isLoading={isProcessing}
          label='Create'
          type='submit'
          variant='primary'
        />
      </ModalContent>
    </form>
  );
};

export default ModalIdentityCreate;

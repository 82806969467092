import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';

import SpinnerProps from './Spinner.types';
import styles from './Spinner.module.scss';

const Spinner: FC<SpinnerProps> = ({
  color = 'default',
  display = 'flex',
  size = 'medium',
  ...props
}) => (
  <Box display={display} {...props}>
    <div className={cx(styles.spinner, styles[`color--${color}`], styles[`size--${size}`])} />
  </Box>
);

export default Spinner;

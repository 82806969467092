import { enUS } from 'date-fns/locale/en-US';
import { formatInTimeZone } from 'date-fns-tz';
import { useSearchParams } from 'react-router-dom';
import React, { ReactElement, useEffect, useMemo } from 'react';

import { TimeZone } from 'constants/constants';
import { formatAmount, getUriId, getUriIdAndChainId } from 'utils/format';
import { getBankNameWithAccountNumberPart } from 'helpers/transaction.helpers';
import { useGetTransactionSummaryQuery } from 'store/api/platformApi';
import Box from 'components/core/Box/Box';
import LoaderView from 'components/views/LoaderView/LoaderView';
import Text from 'components/core/Text/Text';

import styles from './PaymentReceiptView.module.scss';

const PaymentReceiptView = (): ReactElement => {
  const [params] = useSearchParams();

  useEffect(() => {
    document.title = 'Payment Receipt';
  }, []);

  const organizationId = useMemo(() => params.get('organizationId'), [params]);
  const transactionId = useMemo(() => params.get('transactionId'), [params]);

  if (!organizationId || !transactionId) {
    return <div>Organization & transaction need to be provided</div>;
  }

  const { data: transactionSummary, isLoading } = useGetTransactionSummaryQuery({
    organizationId,
    transactionId,
  });

  const attributes = useMemo(() => {
    return transactionSummary?.attributes;
  }, [transactionSummary]);

  const bank = useMemo(() => {
    return attributes?.destination?.bank;
  }, [attributes]);

  const sourceAmount = useMemo(() => {
    return `${formatAmount(attributes?.source)} ${getUriIdAndChainId(attributes?.source?.assetType)}`;
  }, [attributes]);

  const paymentAmount = useMemo(() => {
    return `${formatAmount(attributes?.destination)} ${getUriId(attributes?.destination?.assetType)}`;
  }, [attributes]);

  const paymentDate = useMemo(() => {
    return attributes?.effectiveAt
      ? formatInTimeZone(attributes.effectiveAt, TimeZone.ET, 'MMMM d, yyyy', {
          locale: enUS,
        })
      : null;
  }, [attributes]);

  if (isLoading) {
    return <LoaderView />;
  }

  return (
    <div className={styles.page}>
      <Box
        className={styles.receiptHeaderContainer}
        display='flex'
        justifyContent='center'
        paddingBottom={8}
      >
        <Text className={styles.receiptHeader}>{attributes?.clientName}</Text>
      </Box>

      <Box
        display='flex'
        justifyContent='space-between'
        marginTop={8}
        paddingLeft={4}
        paddingRight={4}
      >
        <Box alignItems='flex-start' display='flex' flexDirection='column' gap={1}>
          <Text variant='sectionHeaderStandard'>{bank?.nameOnBankAccount}</Text>
          <Text color='colorCharcoal' variant='bodyCopySmall'>
            {getBankNameWithAccountNumberPart(bank)}
          </Text>
        </Box>
        <Box alignItems='flex-end' display='flex' flexDirection='column' gap={1}>
          <Text color='colorCharcoal' variant='bodyCopySmall'>
            Payment Amount
          </Text>
          <Text variant='sectionHeaderStandard'>{paymentAmount}</Text>
        </Box>
      </Box>
      <div className={styles.tableHeaderWrapper}>
        <Text color='colorCharcoal' variant='labelSmall'>
          Payment Details
        </Text>
      </div>
      <table>
        <tr>
          <th>Date</th>
          <td>{paymentDate}</td>
        </tr>
        <tr>
          <th>Payment ID</th>
          <td>{getUriId(transactionId)}</td>
        </tr>
        <tr>
          <th>From</th>
          <td>
            <Text className={styles.cellMainText} variant='bodyCopySmall'>
              {sourceAmount}
            </Text>
            <Text className={styles.cellSecondaryText} variant='bodyCopySmall'>
              {attributes?.clientName}
            </Text>
          </td>
        </tr>
        <tr>
          <th>To</th>
          <td>
            <Text className={styles.cellMainText} variant='bodyCopySmall'>
              {paymentAmount}
            </Text>
            <Text className={styles.cellSecondaryText} variant='bodyCopySmall'>
              {bank?.nameOnBankAccount} - {getBankNameWithAccountNumberPart(bank)}
            </Text>
          </td>
        </tr>
      </table>
    </div>
  );
};

export default PaymentReceiptView;

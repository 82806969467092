import React, { ReactNode } from 'react';

import {
  ApiTransactionSummary,
  Organization,
  TransactionEvent,
  TransactionStatus,
  TransactionType,
} from 'store/api/api.types';
import { PRINT_ROUTES } from 'router/constants';
import {
  TRANSACTION_COMPLETED_STATUSES,
  TRANSACTION_STATUS_LABELS,
  TRANSACTION_TYPE_LABELS,
} from 'constants/transactions';
import { TransactionActivityStepProps } from 'components/dedicated/TransactionActivityStep/TransactionActivityStep.types';
import { getAttributesByStatus } from 'components/dedicated/TransactionStatusBadge/TransactionStatusBadge.helpers';
import {
  getBankNameWithAccountNumberPart,
  getParsedTransactionAttributes,
} from 'helpers/transaction.helpers';
import { receipt } from 'components/core/Svg/icons';
import Button from 'components/core/Button/Button';
import DepositInstructions from 'components/dedicated/organization/TransactionDetail/DepositInstructions/DepositInstructions';
import Svg from 'components/core/Svg/Svg';
import Text from 'components/core/Text/Text';

/**
 * Used to tell what previous statuses should be hidden in
 * the transaction activity flow based on the current status.
 * E.g. if the transaction is in "compliance review", we should hide
 * "awaiting compliance review".
 */
export const getTransactionStatusGroupsFlows = (
  transactionStatus: TransactionStatus,
): TransactionStatus[][] => {
  const transactionStatusGroups = [
    [
      TransactionStatus.AwaitingComplianceReview,
      TransactionStatus.InComplianceReview,
      TransactionStatus.ComplianceApproved,
    ],
    [
      TransactionStatus.AwaitingComplianceReview,
      TransactionStatus.InComplianceReview,
      TransactionStatus.ComplianceRejected,
    ],
    [TransactionStatus.ProcessingWithdrawal, TransactionStatus.WithdrawalProcessed],
    [TransactionStatus.ProcessingSettlement, TransactionStatus.SettlementProcessed],
    [TransactionStatus.ProcessingPayment, TransactionStatus.PaymentProcessed],
    [TransactionStatus.AwaitingFunds, TransactionStatus.FundsReceived],
    [TransactionStatus.AwaitingFunds, TransactionStatus.Cancelled],
    [TransactionStatus.AwaitingComplianceReview, TransactionStatus.Cancelled],
  ];
  return transactionStatusGroups.filter(group => group.includes(transactionStatus)) || [];
};

/**
 * Used to show default elements past existing transaction events.
 */
export const getDefaultTransactionStatusFlow = (
  transactionType: TransactionType,
): TransactionStatus[] => {
  switch (transactionType) {
    case TransactionType.Deposit:
      return [
        TransactionStatus.Created,
        TransactionStatus.InComplianceReview,
        TransactionStatus.Completed,
      ];
    case TransactionType.Offramp:
      return [
        TransactionStatus.Created,
        TransactionStatus.AwaitingComplianceReview,
        TransactionStatus.InComplianceReview,
        TransactionStatus.ProcessingPayment,
        TransactionStatus.Completed,
      ];
    case TransactionType.Onramp:
      return [
        TransactionStatus.Created,
        TransactionStatus.AwaitingFunds,
        TransactionStatus.InComplianceReview,
        TransactionStatus.ProcessingSettlement,
        TransactionStatus.Completed,
      ];
    case TransactionType.Withdrawal:
      return [
        TransactionStatus.Created,
        TransactionStatus.InComplianceReview,
        TransactionStatus.ProcessingWithdrawal,
        TransactionStatus.Completed,
      ];
    // just in case we need to fallback gracefully:
    default:
      return [TransactionStatus.Created, TransactionStatus.Completed];
  }
};

/**
 * Decides on the indicator type for the recorded event,
 * to be used in TransactionActivityStep component.
 */
export const getRecordedEventIndicatorType = (
  status: TransactionStatus,
  isLastRecordedEvent: boolean,
): TransactionActivityStepProps['indicatorType'] => {
  if (status === TransactionStatus.ComplianceRejected) {
    return 'failed';
  }
  if (status === TransactionStatus.Cancelled) {
    return 'stopped';
  }
  if (isLastRecordedEvent && status !== TransactionStatus.Completed) {
    return getAttributesByStatus(status).color;
  }
  return 'completed';
};

/**
 * Returns the content for the children element of the recorded event,
 * to be used in TransactionActivityStep component.
 */
export const getRecordedEventChildrenContent = (
  organizationId: Organization['id'],
  transactionSummary: ApiTransactionSummary,
  status: TransactionStatus,
): ReactNode => {
  const { id: transactionId, attributes } = transactionSummary;
  const { transactionType, source } = attributes;
  const { sender } = getParsedTransactionAttributes(attributes);
  if (status === TransactionStatus.FundsReceived) {
    return (
      <Text variant='bodyCopySmall'>
        {sender} - {getBankNameWithAccountNumberPart(source.bank)}
      </Text>
    );
  }
  if (status === TransactionStatus.Completed && transactionType === TransactionType.Offramp) {
    return (
      <>
        <Text marginBottom={4} variant='bodyCopySmall'>
          The payment has been successfully sent. While we can&apos;t specify the exact time it will
          reach the recipient&apos;s account, it usually depends on interbank transfer schedules and
          the recipient&apos;s bank processing times. Rest assured, the funds are in transit.
        </Text>
        <Button
          Icon={<Svg img={receipt} size={[1.8, 1.9]} />}
          label='View Payment Receipt'
          onClick={() =>
            window.open(
              `${PRINT_ROUTES.PAYMENT_RECEIPTS.absolute}?organizationId=${organizationId}&transactionId=${transactionId}`,
              '_blank',
              'noopener,noreferrer',
            )
          }
          size='small'
          variant='secondary'
        />
      </>
    );
  }
  if (status === TransactionStatus.AwaitingComplianceReview) {
    return (
      <Text variant='bodyCopySmall'>
        The transaction is queued for a compliance review. If additional review is required, it will
        be manually assessed before proceeding.
      </Text>
    );
  }
  if (status === TransactionStatus.InComplianceReview) {
    return (
      <Text variant='bodyCopySmall'>
        {[TransactionType.Onramp, TransactionType.Offramp].includes(transactionType)
          ? 'Your transaction is currently under review for compliance checks. This is a standard procedure to ensure that all transactions meet regulatory requirements. Once the review is complete and your transaction passes all necessary checks, we will proceed with processing.'
          : `Your ${TRANSACTION_TYPE_LABELS[transactionType].toLowerCase()} is currently undergoing an automated compliance
        review, which typically takes only a few minutes. If flagged for further review, a manual
        check will be conducted. Once completed and approved, your ${TRANSACTION_TYPE_LABELS[transactionType].toLowerCase()} will proceed as usual.`}
      </Text>
    );
  }
  if (status === TransactionStatus.ComplianceRejected) {
    return (
      <Text variant='bodyCopySmall'>
        Your transaction was reviewed by our compliance team and has unfortunately been rejected due
        to regulatory issues. Please contact our support team for further assistance and
        clarification on this matter.
      </Text>
    );
  }
  if (status === TransactionStatus.ProcessingSettlement) {
    return (
      <Text variant='bodyCopySmall'>
        We&apos;ve received your funds and are currently settling them. The process will be
        completed shortly.
      </Text>
    );
  }
  if (status === TransactionStatus.ProcessingPayment) {
    return (
      <Text variant='bodyCopySmall'>
        Payment for this transaction is processing and will be sent out shortly.
      </Text>
    );
  }
  if (status === TransactionStatus.ProcessingWithdrawal) {
    return (
      <Text variant='bodyCopySmall'>
        Your withdrawal is being processed and will be sent out soon.
      </Text>
    );
  }
  if (status === TransactionStatus.AwaitingFunds) {
    return (
      <>
        <Text marginBottom={4} variant='bodyCopySmall'>
          We&apos;re waiting for your funds to be sent and arrive at our bank. Once received,
          we&apos;ll promptly process the transaction. Funds settled before 4 PM ET are processed
          the same day; after 4 PM ET, they&apos;re processed the next banking day.
        </Text>
        <DepositInstructions transactionAttributes={attributes} />
      </>
    );
  }
  return null;
};

/**
 * This is used only for actual events coming from transaction events
 * endpoint. It is not used to get data for events that are expected
 * to happen later.
 */
export const getRecordedEventData = (
  organizationId: Organization['id'],
  transactionSummary: ApiTransactionSummary,
  transactionEvent: TransactionEvent,
  isLastRecordedEvent: boolean,
): TransactionActivityStepProps => {
  const {
    attributes: { createdAt, newStatus: status },
  } = transactionEvent;
  const isFinalStep = TRANSACTION_COMPLETED_STATUSES.includes(status);
  const indicatorType = getRecordedEventIndicatorType(status, isLastRecordedEvent);
  const children = getRecordedEventChildrenContent(organizationId, transactionSummary, status);
  const date = ['completed', 'failed', 'stopped'].includes(indicatorType) ? createdAt : undefined;

  return {
    children,
    date,
    indicatorType,
    isFinalStep,
    title: TRANSACTION_STATUS_LABELS[status],
  };
};

export const getTransactionActivityStepsProps = (
  organizationId: Organization['id'],
  transactionSummary: ApiTransactionSummary,
  recordedEvents: TransactionEvent[],
): TransactionActivityStepProps[] => {
  const {
    attributes: { transactionType },
  } = transactionSummary;
  const defaultStatusFlow = getDefaultTransactionStatusFlow(transactionType);

  // Take all recorded events and traverse backwards, for every event finding if it belongs to any of the status groups. If it does, remove previous statuses from that group from the recoded events:
  const reversedRecordedEvents = recordedEvents.slice().reverse();
  const filteredReversedRecordedEvents: TransactionEvent[] = [];
  for (let i = 0; i < reversedRecordedEvents.length; i += 1) {
    filteredReversedRecordedEvents.push(reversedRecordedEvents[i]);

    const { newStatus: currentStatus } = reversedRecordedEvents[i].attributes;
    const statusGroupFlows = getTransactionStatusGroupsFlows(currentStatus);
    if (statusGroupFlows.length > 0) {
      for (let j = 0; j < statusGroupFlows.length; j += 1) {
        const statusGroupFlow = statusGroupFlows[j];
        const reversedStatusGroup = statusGroupFlow.slice().reverse();
        // remove existing status and what comes before it from the group
        const statusIndex = reversedStatusGroup.indexOf(currentStatus);
        const filteredStatusGroup = reversedStatusGroup.slice(statusIndex);
        filteredStatusGroup.shift();
        while (filteredStatusGroup.length > 0) {
          const status = filteredStatusGroup.shift();
          // skip previous statuses from considered group
          if (reversedRecordedEvents[i + 1]?.attributes.newStatus === status) {
            i += 1;
          }
        }
      }
    }
  }
  const activitySteps: TransactionActivityStepProps[] = filteredReversedRecordedEvents
    .reverse()
    // filter out events of uknown status
    .filter(event => Object.values(TransactionStatus).includes(event.attributes.newStatus))
    .map((event, index) =>
      getRecordedEventData(
        organizationId,
        transactionSummary,
        event,
        index === filteredReversedRecordedEvents.length - 1,
      ),
    );

  let futureSteps: TransactionActivityStepProps[] = [];
  if (!TRANSACTION_COMPLETED_STATUSES.includes(transactionSummary.attributes.status)) {
    // Take the last event from recorded events, find it in the default flow and remove it with all the previous statuses from the flow:
    const expectedFutureEvents =
      recordedEvents.length > 0
        ? defaultStatusFlow.slice(
            defaultStatusFlow.indexOf(
              recordedEvents[recordedEvents.length - 1].attributes.newStatus,
            ) + 1,
          )
        : defaultStatusFlow;
    futureSteps = expectedFutureEvents.map((status, index) => ({
      indicatorType: 'empty',
      isFinalStep: index === expectedFutureEvents.length - 1,
      title: TRANSACTION_STATUS_LABELS[status],
    }));
  }

  return [...activitySteps, ...futureSteps];
};

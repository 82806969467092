import React, { FC, useMemo, useState } from 'react';

import {
  ApiTransactionSummaryAttributes,
  TransactionDepositInstructions,
  TransactionDepositInstructionsType,
} from 'store/api/api.types';
import { TabProps } from 'components/core/Tab/Tab.types';
import { formatAssetType } from 'utils/format';
import Box from 'components/core/Box/Box';
import List from 'components/core/List/List';
import ListItem from 'components/core/ListItem/ListItem';
import Tabs from 'components/core/Tabs/Tabs';
import Text from 'components/core/Text/Text';

import { getInstructionFields, getInstructionTypeTabLabel } from './DepositInstructions.utils';
import styles from './DepositInstructions.module.scss';

const RequiredFieldLabelIcon = (
  <Text color='colorRed' variant='labelStandard'>
    *
  </Text>
);

const RequiredFieldsMessage = (
  <Box marginBottom={6} marginTop={6}>
    <Box display='flex' gap={4}>
      <Text color='colorBlackWarm' display='inline-flex' gap={1} variant='sectionHeaderStandard'>
        {RequiredFieldLabelIcon}
        All the details above are required.
      </Text>
    </Box>
    <Text color='colorCharcoal' marginTop={2}>
      Missing any required information may cause delays in processing your transaction.
    </Text>
  </Box>
);

const RequiredFieldLabel = ({ label }: { label: string }) => (
  <Text display='inline-flex' gap={1} Tag='label' variant='labelSmallSubLabel'>
    {label}
    {RequiredFieldLabelIcon}
  </Text>
);

const DepositInstructions: FC<{
  transactionAttributes: ApiTransactionSummaryAttributes;
}> = ({ transactionAttributes }) => {
  const [selectedIndex, setSelectedIndex] = useState(0);

  const depositInstructions = useMemo(() => {
    if (transactionAttributes.ramp) {
      return transactionAttributes.ramp.depositInstructions || [];
    }

    if (transactionAttributes.fx) {
      return transactionAttributes.fx.payInInstructions || [];
    }

    return [];
  }, [transactionAttributes]);

  const instructions: TransactionDepositInstructions = useMemo(
    () => depositInstructions[selectedIndex < depositInstructions.length ? selectedIndex : 0],
    [depositInstructions, selectedIndex],
  );

  const instructionFields = useMemo(
    () => [
      {
        label: 'Amount Needed',
        props: {
          valueForCopyButton: undefined,
        },
        value: <Text variant='labelSmall'>{formatAssetType(transactionAttributes.source)}</Text>,
      },
      ...getInstructionFields(instructions),
    ],
    [instructions, transactionAttributes],
  );

  const tabs = useMemo((): TabProps[] => {
    return depositInstructions.map(
      (instruction, index) =>
        ({
          isActive: index === selectedIndex,
          label: getInstructionTypeTabLabel(instruction.type),
          onClick: () => {
            setSelectedIndex(index);
          },
        }) as TabProps,
    );
  }, [depositInstructions, selectedIndex]);

  const shouldShowRequiredFieldsMessage = instructionFields.some(
    field => field.isRequired === true,
  );
  return (
    <>
      {tabs.length > 1 && <Tabs marginBottom={4} tabs={tabs} variant='picker' />}
      {instructions?.type === TransactionDepositInstructionsType.PIX && (
        <img
          alt='QR Code'
          className={styles.qrCode}
          src={`data:image/png;base64,${instructions.qrCodeImageBase64}`}
        />
      )}
      {instructionFields.length > 0 && (
        <List>
          {instructionFields.map(({ label, props, value, isRequired }) => (
            <ListItem
              key={label}
              label={isRequired ? <RequiredFieldLabel label={label} /> : label}
              valueForCopyButton={value}
              {...props}
            >
              {value}
            </ListItem>
          ))}

          {shouldShowRequiredFieldsMessage && (
            <ListItem useSingleCellListItem>{RequiredFieldsMessage}</ListItem>
          )}
        </List>
      )}
    </>
  );
};

export default DepositInstructions;

import { useLocation } from 'react-router-dom';
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import cx from 'classnames';

import { chevronDown } from 'components/core/Svg/icons';
import { getScrollableParent } from 'utils/getScrollableParent';
import Button from 'components/core/Button/Button';
import ReactPortal from 'components/core/ReactPortal/ReactPortal';
import Svg from 'components/core/Svg/Svg';
import generateUid from 'utils/generateUid';

import DropdownProps from './Dropdown.types';
import styles from './Dropdown.module.scss';

const Dropdown: FC<DropdownProps> = ({
  children,
  className,
  dataTestId,
  menuPosition = 'right',
  menuWidth = 'normal',
  showArrow,
  Icon,
  IconLeft,
  iconPosition,
  variant = 'dropdownOpaque',
  ...buttonProps
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [dropdownMenuPosition, setDropdownMenuPosition] = useState({
    left: 'auto',
    top: 'auto',
  });
  const location = useLocation();
  const dropdownButtonRef = useRef<HTMLButtonElement>(null);
  const dropdownMenuRef = useRef<HTMLDivElement>(null);
  const isOpenState = useRef(isOpen);

  const toggleMenu = isOpenCurrent => {
    isOpenState.current = isOpenCurrent;
    setIsOpen(isOpenCurrent);
  };

  const updateDropdownMenuPosition = useCallback(() => {
    if (isOpen && dropdownButtonRef.current && dropdownMenuRef.current) {
      const dropdownButtonRect = dropdownButtonRef.current.getBoundingClientRect();
      const dropdownMenuRect = dropdownMenuRef.current.getBoundingClientRect();
      const windowWidth = window.innerWidth;
      const rightScreenEdgeOffset = 16;

      const absoluteButtonTop = dropdownButtonRect.top + window.scrollY;
      const absoluteButtonLeft = dropdownButtonRect.left + window.scrollX;
      const absoluteButtonRight = dropdownButtonRect.right + window.scrollX;

      let newLeftPosition;
      if (menuPosition === 'left') {
        newLeftPosition = absoluteButtonLeft;
      } else {
        newLeftPosition = absoluteButtonRight - dropdownMenuRect.width;
      }

      // Check if dropdown overflows the window's width.
      // If it does, pin it to the right side of the window.
      if (
        absoluteButtonRight + rightScreenEdgeOffset > windowWidth ||
        newLeftPosition + dropdownMenuRect.width + rightScreenEdgeOffset > windowWidth
      ) {
        newLeftPosition = windowWidth - dropdownMenuRect.width - rightScreenEdgeOffset;
      } else if (newLeftPosition < rightScreenEdgeOffset) {
        newLeftPosition = rightScreenEdgeOffset;
      }

      const newPosition = {
        left: `${newLeftPosition}px`,
        top: `${absoluteButtonTop + dropdownButtonRect.height}px`,
      };

      setDropdownMenuPosition(newPosition);
    }
  }, [isOpen, menuPosition]);

  useEffect(() => {
    updateDropdownMenuPosition();

    const scrollableParent = getScrollableParent(dropdownButtonRef.current);

    window.addEventListener('scroll', updateDropdownMenuPosition);
    window.addEventListener('resize', updateDropdownMenuPosition);
    if (scrollableParent) {
      scrollableParent.addEventListener('scroll', updateDropdownMenuPosition);
    }

    return () => {
      window.removeEventListener('scroll', updateDropdownMenuPosition);
      window.removeEventListener('resize', updateDropdownMenuPosition);
      if (scrollableParent) {
        scrollableParent.removeEventListener('scroll', updateDropdownMenuPosition);
      }
    };
  }, [isOpen, updateDropdownMenuPosition]);

  const onClickOutside = ({ target }) => {
    const dropdownBUtton = dropdownButtonRef.current;
    if (isOpenState.current && !dropdownBUtton?.contains(target)) {
      toggleMenu(!isOpenState.current);
    }
  };

  const dropdownPortalId = useMemo(() => `dropdownPortal-${generateUid()}`, []);

  useEffect(() => {
    toggleMenu(false);
  }, [location]);

  useEffect(() => {
    window.addEventListener('click', onClickOutside);
    return () => window.removeEventListener('click', onClickOutside);
    // eslint-disable-next-line
  }, []);

  let dropdownButtonIcon = Icon;
  let dropdownButtonIconPosition = iconPosition;
  let dropdownContentShiftInRems = 0;
  if (['dropdownOpaque', 'dropdownTransparent'].includes(variant)) {
    dropdownButtonIcon = <Svg img={chevronDown} size={[1, 0.6]} />;
    dropdownButtonIconPosition = 'right';
  }

  // Logic for shifting dropdown content so that content's arrow points exactly at icon's center.
  const iconRef = useRef<HTMLButtonElement>(null);
  if (['icon'].includes(variant) && showArrow) {
    const iconWidth = iconRef.current?.clientWidth || 0;
    const arrowWidth = 14;
    const arrowHorizontalShift = 8;
    const dropdownContentShiftMod = menuPosition === 'right' ? -1 : 1;
    dropdownContentShiftInRems =
      ((iconWidth / 2 - arrowWidth / 2 - arrowHorizontalShift) / 10) * dropdownContentShiftMod;
  }

  return (
    <div
      className={cx(styles.root, styles[`variant--${variant}`], className)}
      data-testid={dataTestId}
    >
      <Button
        ref={dropdownButtonRef}
        Icon={dropdownButtonIcon}
        IconLeft={IconLeft}
        iconPosition={dropdownButtonIconPosition}
        iconRef={iconRef}
        isActive={isOpen}
        onClick={() => toggleMenu(!isOpenState.current)}
        variant={variant}
        {...buttonProps}
      />
      <ReactPortal wrapperId={dropdownPortalId}>
        <div
          ref={dropdownMenuRef}
          className={cx(
            styles.dropdownContent,
            styles[`menuPosition--${menuPosition}`],
            styles[`menuWidth--${menuWidth}`],
            showArrow && styles.showArrow,
            isOpen && styles.isOpen,
          )}
          onClick={() => toggleMenu(!isOpenState.current)}
          style={{
            ...dropdownMenuPosition,
            transform: `translateX(${dropdownContentShiftInRems}rem)`,
          }}
        >
          {children}
        </div>
      </ReactPortal>
    </div>
  );
};

export default Dropdown;

import React from 'react';
import omit from 'lodash/omit';

import { SvgProps } from 'components/core/Svg/Svg.types';
// eslint-disable-next-line no-restricted-syntax
import * as icons from 'components/core/Svg/icons';
import Svg from 'components/core/Svg/Svg';

import { IconProps } from './Icon.types';

export const Icon: React.FC<IconProps> = ({ iconName, ...rest }) => {
  const icon = icons[iconName];
  const svgProps: SvgProps = {
    img: icon,
    ...omit(rest, 'img'),
  };
  return <Svg {...svgProps} />;
};
export default Icon;

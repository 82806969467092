import { generatePath, useNavigate, useParams } from 'react-router-dom';
import React, { ReactElement, useCallback, useEffect, useMemo, useState } from 'react';
import cx from 'classnames';

import { ActionName } from 'context/CurrentUserContext.types';
import { ORGANIZATIONS_ROUTES } from 'router/constants';
import { colorGreen } from 'styles/_colors';
import {
  useCreateInviteMutation,
  useGetCurrentUserQuery,
  useGetOrganizationQuery,
} from 'store/api/rootApi';
import { useCurrentUser } from 'context/CurrentUserContext';
import Button from 'components/core/Button/Button';
import Icon from 'components/core/Icon/Icon';
import InputField from 'components/core/Form/InputField/InputField';
import Text from 'components/core/Text/Text';

import styles from './InviteMembersView.module.scss';

type InviteMemberEntry = {
  email: string;
  error?: false | string;
  id: number;
  invited: boolean;
};

const InviteMembersView = (): ReactElement => {
  const navigate = useNavigate();
  const { isUserAllowedTo } = useCurrentUser();

  const createNewMember = (): InviteMemberEntry => ({
    email: '',
    error: false,
    id: Date.now(),
    invited: false,
  });
  const { organizationId } = useParams() as { organizationId: string };
  const { data: organization } = useGetOrganizationQuery({ organizationId });
  const { isFetching: isFetchingCurrentUser } = useGetCurrentUserQuery();
  const [newMembers, setNewMembers] = useState<Array<InviteMemberEntry>>([createNewMember()]);
  const [emailRefs, setEmailRefs] = useState<React.Ref<HTMLInputElement>>(
    [] as React.Ref<HTMLInputElement>[] | any,
  );
  const [isInvitingMembers, setIsInvitingMembers] = useState(false);
  const [createInvite] = useCreateInviteMutation();

  const canCreateInvite = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateInvite),
    [organizationId, isUserAllowedTo],
  );

  const navigateToOrganizations = useCallback(() => {
    const newOrgPath = generatePath(ORGANIZATIONS_ROUTES.ORGANIZATION.absolute, {
      organizationId,
    });
    navigate(newOrgPath);
  }, [navigate, organizationId]);

  useEffect(() => {
    if (!isFetchingCurrentUser && !canCreateInvite) {
      navigateToOrganizations();
    }
  }, [canCreateInvite, isFetchingCurrentUser, navigateToOrganizations]);

  useEffect(() => {
    // Initialize refs for each of the email inputs on component mount or update
    setEmailRefs(refs =>
      Array(newMembers.length)
        .fill(null)
        .map((_, i) => refs[i] || React.createRef()),
    );
  }, [newMembers.length]);

  useEffect(() => {
    if (newMembers.every(newMember => newMember.invited)) {
      navigateToOrganizations();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [newMembers, navigateToOrganizations]);

  const updateNewMembersAtIndices = (updates: Record<number, Partial<InviteMemberEntry>>) => {
    const newMembersCopy = [...newMembers];
    for (const [i, update] of Object.entries(updates)) {
      newMembersCopy[i] = {
        ...newMembersCopy[i],
        ...update,
      };
    }
    setNewMembers(newMembersCopy);
    return newMembersCopy;
  };

  const updateNewMemberAtIndex = (
    i,
    updates: Partial<InviteMemberEntry>,
    currentNewMembers = newMembers,
  ) => {
    const newMembersCopy = [...currentNewMembers];
    newMembersCopy[i] = {
      ...newMembersCopy[i],
      ...updates,
    };
    setNewMembers(newMembersCopy);
    return newMembersCopy;
  };

  const inviteMembers = async () => {
    setIsInvitingMembers(true);
    const errorIndex = 0; // used for conditional access on arrays
    let currentNewMembers = [...newMembers].filter(newMember => !newMember.invited);
    const memberUpdates: Record<number, Partial<InviteMemberEntry>> = {};
    for (const [i] of currentNewMembers.entries()) {
      if (!emailRefs![i].current?.checkValidity()) {
        // trigger html validation
        memberUpdates[i] = { error: 'Please enter a valid email address' };
      } else {
        memberUpdates[i] = { error: false };
      }
    }
    updateNewMembersAtIndices(memberUpdates);

    if (Object.values(memberUpdates).some(update => update.error)) {
      setIsInvitingMembers(false);
      return;
    }
    for (const [i, newMember] of currentNewMembers.entries()) {
      // eslint-disable-next-line no-await-in-loop
      const result = await createInvite({ email: newMember.email, organizationId });
      if ('error' in result) {
        // TODO: Strengthen type of returntype of createInvite
        const errorResult = result.error as any;
        const errorMessage =
          errorResult.data?.errors?.[errorIndex]?.title ?? errorResult.data?.message?.[errorIndex];
        if (errorMessage) {
          currentNewMembers = updateNewMemberAtIndex(i, { error: errorMessage }, currentNewMembers);
        }
      } else {
        currentNewMembers = updateNewMemberAtIndex(
          i,
          { error: false, invited: true },
          currentNewMembers,
        );
      }
    }
    setIsInvitingMembers(false);
  };

  const handleInviteMembers = e => {
    e.preventDefault();
    inviteMembers();
    return false;
  };

  const handleNewMemberChangeFn = i => e => {
    updateNewMemberAtIndex(i, { email: e.target.value });
  };

  const validateInputOnBlur =
    i => (e: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => {
      if (!e.target.checkValidity()) {
        updateNewMemberAtIndex(i, { error: 'Please enter a valid email address' });
      } else {
        updateNewMemberAtIndex(i, { error: false });
      }
    };

  const handleNewMember = () => {
    const newMembersCopy = [...newMembers];
    newMembersCopy.push(createNewMember());
    setNewMembers(newMembersCopy);
  };

  const handleSkip = async () => {
    navigateToOrganizations();
  };

  const handleRemoveMemberFn = i => () => {
    if (newMembers.length === 1) {
      return;
    }
    const newMembersCopy = [...newMembers];
    newMembersCopy.splice(i, 1);
    setNewMembers(newMembersCopy);
  };

  const iconForInput = (index: number) => {
    if (newMembers[index].invited) {
      return (
        <Icon
          className={styles.itemRowIcon}
          color={colorGreen}
          iconName='circledCheckmarkContour'
          size={1.6}
        />
      );
    }
    if (newMembers.length > 1) {
      return (
        <Icon
          className={cx(styles.itemRowIcon, styles.interactiveIcon)}
          iconName='crossmark'
          onClick={handleRemoveMemberFn(index)}
          size={1.6}
        />
      );
    }
    return null;
  };

  return (
    <div className={styles.root}>
      <Text align='center' className={styles.header} variant='legacyTitle'>
        Invite Members
      </Text>
      <Text align='center'>Invite your team members to join {organization?.name}</Text>
      <form className={styles.form} onSubmit={handleInviteMembers}>
        <div className={styles.inviteMembersEntries}>
          {newMembers.map((newMember, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <div key={i} className={styles.itemRow}>
              <InputField
                key={`newMembers[${newMember.id}]`}
                ref={emailRefs![i]}
                className={styles.input}
                error={newMembers[i].error || ''}
                isDisabled={isInvitingMembers || newMembers[i].invited}
                name={`newMembers[${newMember.id}]`}
                onBlur={validateInputOnBlur(i)}
                onChange={handleNewMemberChangeFn(i)}
                placeholder='Enter Email Address'
                type='email'
                value={newMember.email}
              />
              {iconForInput(i)}
            </div>
          ))}
        </div>
        <Button
          className={styles.addNewMember}
          label='+ Add Another Email Address'
          onClick={handleNewMember}
          variant='text'
        />
        <div className={styles.horizontalButtons}>
          <Button label='Skip for Now' onClick={handleSkip} type='button' variant='secondary' />
          <Button
            isLoading={isInvitingMembers}
            label='Send Invites'
            onClick={handleInviteMembers}
            variant='primary'
          />
        </div>
      </form>
    </div>
  );
};

export default InviteMembersView;

import React, { FC, useMemo, useState } from 'react';

import { QuoteViewStep } from 'components/views/app/organization/SendMoney/QuoteView/QuoteView.types';
import { showError } from 'services/notificationService';
import { useAcceptAgreementMutation } from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import CheckboxField from 'components/core/Form/CheckboxField/CheckboxField';
import ExternalLink from 'components/core/ExternalLink/ExternalLink';
import List from 'components/core/List/List';
import ListItemWithIcon from 'components/core/ListItemWithIcon/ListItemWithIcon';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';

import { ColumnAgreementProps } from './ColumnAgreement.types';
import { checkIfHasAgreedToColumnAgreement } from './ColumnAgreement.utils';
import styles from './ColumnAgreement.module.scss';

const ColumnAgreement: FC<ColumnAgreementProps> = ({ changeStep, organization }) => {
  const [hasAgreed, setHasAgreed] = useState<boolean>(false);

  const [acceptAgreement, { isLoading }] = useAcceptAgreementMutation();

  const hasAgreedToColumnAgreement = useMemo(() => {
    return checkIfHasAgreedToColumnAgreement(organization);
  }, [organization]);

  const onClick = () => {
    acceptAgreement({ agreementId: 'COLUMN', organizationId: organization?.id }).then(result => {
      if (result.error) {
        showError('Agreement cannot be accepted. Please try later.');
      } else {
        changeStep(QuoteViewStep.PREVIEW);
      }
    });
  };

  return (
    <>
      <Text align='center' variant='sectionHeaderLarge'>
        Updates to our Terms and Conditions
      </Text>
      <List className={styles.list}>
        <ListItemWithIcon>
          <ExternalLink link='https://conduitpay.com/terms-of-service'>Terms Of Use</ExternalLink>
          <Text variant='subCopySmall'>
            Terms &amp; Conditions of using our website and other services.
          </Text>
        </ListItemWithIcon>

        <ListItemWithIcon>
          <ExternalLink link='https://conduitpay.com/service-agreement'>
            MSA General Terms &amp; Conditions
          </ExternalLink>
          <Text variant='subCopySmall'>
            Terms &amp; Conditions of our Master Services Agreement.
          </Text>
        </ListItemWithIcon>

        <ListItemWithIcon>
          <ExternalLink link='https://conduitpay.com/restricted-industries'>
            Restricted Industries
          </ExternalLink>
          <Text variant='subCopySmall'>Industries that are prevented from using our services.</Text>
        </ListItemWithIcon>

        <ListItemWithIcon>
          <ExternalLink link='https://conduitpay.com/restricted-countries'>
            Restricted Countries
          </ExternalLink>
          <Text variant='subCopySmall'>
            List of countries where additional reviews are required and/or where transactions are
            prohibited.
          </Text>
        </ListItemWithIcon>
      </List>
      {hasAgreedToColumnAgreement && (
        <div className={styles.footer}>
          <Button
            label='Go Back'
            onClick={() => changeStep(QuoteViewStep.PREVIEW)}
            type='button'
            variant='secondary'
          />
        </div>
      )}
      {!hasAgreedToColumnAgreement && (
        <div className={styles.footer}>
          <Noticebox className={styles.note} showIcon={false} variant='note'>
            <CheckboxField
              onClick={($event: any) => {
                setHasAgreed($event.target.checked);
              }}
            />
            <Text align='left' variant='bodyCopySmall'>
              I attest that I have reviewed the updates and agree to all of the above.
            </Text>
          </Noticebox>

          <div className={styles.buttons}>
            <Button
              label='Go Back'
              onClick={() => changeStep(QuoteViewStep.FORM)}
              type='button'
              variant='secondary'
            />
            <Button
              isDisabled={!hasAgreed || isLoading}
              label='Confirm'
              onClick={onClick}
              type='submit'
              variant='primary'
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ColumnAgreement;

import React, { FC, useMemo, useState } from 'react';

import { Organization, OrganizationMember } from 'store/api/api.types';
import { SYSTEM_ROLE_DESCRIPTIONS } from 'constants/constants';
import { SelectFieldOption } from 'components/core/Form/SelectField/SelectField.types';
import { getFieldResponseErrorMessage, isGenericErrorType } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { uppercaseFirstChar } from 'utils/uppercaseFirstChar';
import { useChangeMemberRoleMutation, useGetOrganizationRolesQuery } from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import SelectField from 'components/core/Form/SelectField/SelectField';
import Spinner from 'components/core/Spinner/Spinner';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

export const modalChangeMemberRoleTitle = 'Change Role';

const ModalChangeMemberRole: FC<{
  member: OrganizationMember;
  organizationId: Organization['id'];
}> = ({ organizationId, member }) => {
  const [newMemberRole, setNewMemberRole] = useState(member.roles[0].id);
  const { data: organizationRoles, isFetching: isFetchingRoles } = useGetOrganizationRolesQuery({
    organizationId,
  });

  const [changeRoles, { isLoading: isChangingRoles, error }] = useChangeMemberRoleMutation();

  const roleOptions: SelectFieldOption[] = useMemo(
    () =>
      (organizationRoles || []).map(role => ({
        label: uppercaseFirstChar(role.name),
        sublabel: SYSTEM_ROLE_DESCRIPTIONS[role.name],
        value: role.id,
      })),
    [organizationRoles],
  );

  const selectedOption = useMemo(
    () => roleOptions.find(role => role.value === newMemberRole),
    [roleOptions, newMemberRole],
  );

  const handleChangeMemberRole = async e => {
    e.preventDefault();
    const result = await changeRoles({
      memberId: member.id,
      organizationId,
      roleIds: [newMemberRole],
    });
    if (!('error' in result)) {
      modalService.closeCurrentModal();
      showSuccess(
        <Text variant='bodyCopySmall'>
          <strong>{member.email}&apos;s</strong> role has been successfully changed.
        </Text>,
      );
    } else if (isGenericErrorType(result.error)) {
      showError('Failed to change member&apos;s role - please try again later.');
    }
  };

  return (
    <form onSubmit={handleChangeMemberRole}>
      {isFetchingRoles ? (
        <Spinner />
      ) : (
        <>
          <ModalContent variant='noBottomPadding'>
            <Text>
              Choose a new role for{' '}
              <Text Tag='span' variant='legacyBodyEmphasized'>
                {member.email}
              </Text>
              .
            </Text>
            <SelectField
              error={getFieldResponseErrorMessage('roleIds', error)}
              isDisabled={isChangingRoles}
              isRequired
              label='Role'
              name='roleId'
              onChange={({ target: { value } }) => setNewMemberRole(value)}
              options={roleOptions}
              placeholder='Select role'
              value={selectedOption}
            />
          </ModalContent>
          <ModalContent variant='footerButtonsWithoutTopBorder'>
            <Button
              isDisabled={isChangingRoles}
              label='Cancel'
              onClick={() => {
                modalService.closeCurrentModal();
              }}
              variant='secondary'
            />
            <Button isLoading={isChangingRoles} label='Save' type='submit' variant='primary' />
          </ModalContent>
        </>
      )}
    </form>
  );
};

export default ModalChangeMemberRole;

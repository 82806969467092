import React, { FC, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { Organization, OrganizationMember } from 'store/api/api.types';
import { useChangeMemberRoleMutation } from 'store/api/rootApi';
import { useCurrentUser } from 'context/CurrentUserContext';
import Dropdown from 'components/core/Dropdown/Dropdown';
import Icon from 'components/core/Icon/Icon';
import MenuItem from 'components/core/MenuItem/MenuItem';
import ModalChangeMemberRole, {
  modalChangeMemberRoleTitle,
} from 'components/dedicated/ModalChangeMemberRole/ModalChangeMemberRole';
import ModalDeleteMember, {
  modalDeleteMemberTitle,
} from 'components/dedicated/ModalDeleteMember/ModalDeleteMember';
import modalService from 'services/modalService';

import styles from './MemberActionsButton.module.scss';

const MemberActionsButton: FC<{
  member: OrganizationMember;
  organizationId: Organization['id'];
}> = ({ member, organizationId }) => {
  const { user, isUserAllowedTo } = useCurrentUser();
  const [, { isLoading: isChangingRoles }] = useChangeMemberRoleMutation();

  const canChangeMemberRoles = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.ChangeMemberRoles),
    [organizationId, isUserAllowedTo],
  );

  const canDeleteMember = useMemo(
    () => user?.email !== member.email && isUserAllowedTo(organizationId, ActionName.DeleteMember),
    [user, member, organizationId, isUserAllowedTo],
  );

  const handleChangeRoles = () => {
    modalService.openModal(
      {
        disableScrollOnLargeScreen: true,
        title: modalChangeMemberRoleTitle,
      },
      <ModalChangeMemberRole member={member} organizationId={organizationId} />,
    );
  };

  const handleDeleteMember = () => {
    modalService.openModal(
      {
        disableScrollOnLargeScreen: true,
        title: modalDeleteMemberTitle,
      },
      <ModalDeleteMember member={member} organizationId={organizationId} />,
    );
  };

  const showMenu = canChangeMemberRoles || canDeleteMember;
  const isProcessing = isChangingRoles;

  return showMenu ? (
    <Dropdown
      className={styles.alignRight}
      Icon={<Icon iconName='more' size={2.4} />}
      isDisabled={isProcessing}
      isLoading={isProcessing}
      variant='icon'
    >
      {canChangeMemberRoles && <MenuItem label='Change Role' onClick={handleChangeRoles} />}
      {canDeleteMember && <MenuItem label='Delete Member' onClick={handleDeleteMember} />}
    </Dropdown>
  ) : null;
};

export default MemberActionsButton;

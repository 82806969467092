import React, { FC } from 'react';
import cx from 'classnames';

import Box from 'components/core/Box/Box';
import Tab from 'components/core/Tab/Tab';

import { TabsProps } from './Tabs.types';
import styles from './Tabs.module.scss';

const Tabs: FC<TabsProps> = ({ className, isFullWidth, tabs, variant = 'primary', ...props }) => (
  <Box
    className={cx(
      styles.root,
      styles[`variant--${variant}`],
      isFullWidth && styles.isFullWidth,
      className,
    )}
    {...props}
  >
    <div className={styles.wrapper}>
      {tabs.map((tab, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Tab key={`${index}-${tab.isActive}`} className={styles.tab} {...tab} variant={variant} />
      ))}
    </div>
  </Box>
);

export default Tabs;

import { ToastContent, ToastOptions, toast } from 'react-toastify';
import React from 'react';

import {
  circledCheckmark,
  circledCross,
  circledExclamation,
  help,
} from 'components/core/Svg/icons';
import Svg from 'components/core/Svg/Svg';

type ReactToastProxy = (
  content: ToastContent,
  options?: Omit<ToastOptions, 'type' | 'icon'>,
) => void;

const showError: ReactToastProxy = (content, options) => {
  toast(content, {
    icon: <Svg img={circledCross} />,
    type: 'error',
    ...options,
  });
};

const showInfo: ReactToastProxy = (content, options) => {
  toast(content, {
    icon: <Svg img={help} />,
    type: 'info',
    ...options,
  });
};

const showSuccess: ReactToastProxy = (content, options) => {
  toast(content, {
    icon: <Svg img={circledCheckmark} />,
    type: 'success',
    ...options,
  });
};

const showWarning: ReactToastProxy = (content, options) => {
  toast(content, {
    icon: <Svg img={circledExclamation} />,
    type: 'warning',
    ...options,
  });
};

export { showError, showInfo, showSuccess, showWarning };

import React, { FC, useMemo } from 'react';

import { AssetType } from 'constants/assetTypes.types';
import Noticebox from 'components/core/Noticebox/Noticebox';
import Text from 'components/core/Text/Text';

const HolidayMessage: FC<{ sourceAssetType?: AssetType; targetAssetType?: AssetType }> = ({
  sourceAssetType,
  targetAssetType,
}) => {
  const holidayMessage = useMemo(() => {
    const today = new Date();

    if (sourceAssetType?.holidays) {
      const holidayMatch = sourceAssetType.holidays.find(
        holiday => today > holiday.dateStart && today < holiday.dateEnd,
      );
      if (holidayMatch) {
        return holidayMatch.message;
      }
    }

    if (targetAssetType?.holidays) {
      const holidayMatch = targetAssetType.holidays.find(
        holiday => today > holiday.dateStart && today < holiday.dateEnd,
      );
      if (holidayMatch) {
        return holidayMatch.message;
      }
    }

    return null;
  }, [sourceAssetType, targetAssetType]);

  if (holidayMessage) {
    return (
      <Noticebox marginTop={4} variant='warning'>
        <Text>{holidayMessage}</Text>
      </Noticebox>
    );
  }

  return null;
};

export default HolidayMessage;

import React, { FC } from 'react';
import cx from 'classnames';

import { circledCheckmark, circledCross, circledExclamation } from 'components/core/Svg/icons';
import Box from 'components/core/Box/Box';
import Svg from 'components/core/Svg/Svg';

import NoticeboxProps from './Noticebox.types';
import styles from './Noticebox.module.scss';

const iconMap = {
  error: circledCross,
  info: circledExclamation,
  success: circledCheckmark,
  warning: circledExclamation,
};

const Noticebox: FC<NoticeboxProps> = ({
  children,
  className,
  showIcon = true,
  variant = 'info',
  ...props
}) => (
  <Box className={cx(styles.root, className, styles[`variant--${variant}`])} {...props}>
    {showIcon && <Svg className={styles.icon} img={iconMap[variant]} size={2} />}
    <div className={styles.content}>{children}</div>
  </Box>
);

export default Noticebox;

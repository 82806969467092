import React, { FC } from 'react';

import { Organization, OrganizationMember } from 'store/api/api.types';
import { isGenericErrorType } from 'store/api/api.utils';
import { showError, showSuccess } from 'services/notificationService';
import { useDeleteMemberMutation } from 'store/api/rootApi';
import Button from 'components/core/Button/Button';
import ModalContent from 'components/core/Modal/ModalContent/ModalContent';
import Text from 'components/core/Text/Text';
import modalService from 'services/modalService';

export const modalDeleteMemberTitle = 'Remove Member';

const ModalDeleteMember: FC<{
  member: OrganizationMember;
  organizationId: Organization['id'];
}> = ({ organizationId, member }) => {
  const [deleteMember, { isLoading: isDeleting }] = useDeleteMemberMutation();

  const handleChangeMemberRole = async e => {
    e.preventDefault();
    const result = await deleteMember({
      memberId: member.id,
      organizationId,
    });
    if (!('error' in result)) {
      modalService.closeCurrentModal();
      showSuccess(
        <Text variant='bodyCopySmall'>
          Member <strong>{member.email}</strong> has been successfully removed.
        </Text>,
      );
    } else if (isGenericErrorType(result.error)) {
      showError('Failed to remove member - please try again later.');
    }
  };

  return (
    <form onSubmit={handleChangeMemberRole}>
      <ModalContent>
        <Text>
          Are you sure you want to remove{' '}
          <Text Tag='span' variant='legacyBodyEmphasized'>
            {member.email}
          </Text>{' '}
          from the organization?
        </Text>
      </ModalContent>
      <ModalContent variant='footerButtonsWithoutTopBorder'>
        <Button
          isDisabled={isDeleting}
          label='Cancel'
          onClick={() => {
            modalService.closeCurrentModal();
          }}
          variant='secondary'
        />
        <Button isLoading={isDeleting} label='Remove' type='submit' variant='primary' />
      </ModalContent>
    </form>
  );
};

export default ModalDeleteMember;

import { fromWei } from 'web3-utils';
import { toNumber } from 'lodash';
import React, { FC, useMemo } from 'react';

import { ActionName } from 'context/CurrentUserContext.types';
import { AssetType } from 'constants/assetTypes.types';
import { ModalDeposit, ModalWithdraw } from 'components/dedicated/organization/send-money';
import { NO_PERMISSION_MESSAGE } from 'constants/constants';
import { Organization } from 'store/api/api.types';
import { formatAmount, getUriIdAndChainId, isFiat } from 'utils/format';
import { useCurrentUser } from 'context/CurrentUserContext';
import Button from 'components/core/Button/Button';
import Icon from 'components/core/Icon/Icon';
import Text from 'components/core/Text/Text';
import Tooltip from 'components/core/Tooltip/Tooltip';
import modalService from 'services/modalService';

import styles from './StablecoinHeader.module.scss';

const StablecoinHeader: FC<{
  assetTypes?: AssetType[];
  organizationId: Organization['id'];
}> = ({ organizationId, assetTypes }) => {
  const stablecoins = useMemo(() => {
    return assetTypes
      ?.filter(assetType => !isFiat(assetType.id) && assetType.depositAddress)
      .map(stablecoin => ({ ...stablecoin, min: stablecoin.withdrawMin }) as AssetType);
  }, [assetTypes]);

  const { isUserAllowedTo } = useCurrentUser();

  const canWithdraw = useMemo(
    () => isUserAllowedTo(organizationId, ActionName.CreateTransaction),
    [organizationId, isUserAllowedTo],
  );

  const usdBalance = useMemo(() => {
    return stablecoins?.reduce(
      (total, assetType) =>
        total + toNumber(fromWei(assetType?.balance || 0, assetType?.precision || 'Mwei')),
      0,
    );
  }, [stablecoins]);

  return (
    <header className={styles.root}>
      <div>
        <Text variant='bodyCopySmall'>Total Assets</Text>
        <Text variant='legacyTitle'>
          {usdBalance?.toLocaleString('en-US', { currency: 'USD', style: 'currency' })}
        </Text>
      </div>
      {stablecoins?.map(stablecoin => (
        <div key={stablecoin.id} className={styles.stablecoin}>
          <div className={styles.details}>
            <Text variant='bodyCopySmall'>{getUriIdAndChainId(stablecoin)}</Text>
            <Text variant='legacyTitle'>
              <div className={styles.tokenAndChain}>
                <Icon iconName={stablecoin.iconName} size={2.2} />
                {stablecoin.networkIconName && (
                  <Icon iconName={stablecoin.networkIconName} size={1.4} />
                )}
              </div>
              {formatAmount(stablecoin, 3)}
            </Text>
          </div>
          <div className={styles.actions}>
            <Button
              label='Add'
              onClick={() =>
                modalService.openModal(
                  {
                    title: `Add ${getUriIdAndChainId(stablecoin)}`,
                  },
                  <ModalDeposit assetType={stablecoin} />,
                )
              }
              size='small'
              variant='primary'
            />
            <Tooltip
              className={styles.tooltip}
              isHidden={canWithdraw}
              label={NO_PERMISSION_MESSAGE}
            >
              <Button
                isDisabled={!canWithdraw}
                label='Withdraw'
                onClick={() =>
                  modalService.openModal(
                    {
                      title: `Withdraw ${getUriIdAndChainId(stablecoin)}`,
                    },
                    <ModalWithdraw assetType={stablecoin} organizationId={organizationId} />,
                  )
                }
                size='small'
                variant='secondary'
              />
            </Tooltip>
          </div>
        </div>
      ))}
      <div />
    </header>
  );
};

export default StablecoinHeader;
